.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 32px 0 32px;

  width: 365px;
  height: 667px;
}

img {
  width: 100%;
  height: 180px;
  margin-bottom: 48px;
}

.MuiFormControl-root > div {
  border: "1px solid #ffff";
}
