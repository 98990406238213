.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 32px 0 32px;
  margin: 0 auto;
  width: 365px;
  height: 667px;
  h1 {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    color: #5db8ff;
    padding-bottom: 64px;
  }
}

.textField {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 121px;
  width: 100%;
}

.register {
  width: 100%;
}
