.details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;

  width: 365px;
  height: 100%;
}
.table {
  margin-left: 27.5%;
  margin-right: 27.5%;
  margin-top: 35px;
  overflow: scroll;
}
table {
  margin-left: 25%;
  margin: 0px;
  text-align: left;
  width: 800px;
  color: #ffff;
  overflow-x: visible;
  border: 1px solid #fff;
}

th {
  padding: 21px 15px;
  border-bottom: 1px solid #fff;
}

td {
  padding: 21px 15px;
}
