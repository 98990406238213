/*Space Config*/

.app_container {
  max-width: 100%;
  max-height: 100vh;
  margin: 0 auto;
}

.app_content {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  width: 365px;
  height: 100%;
  margin-top: 32px;
  overflow: hidden;
  padding: 0 32px 0 32px;
}

/*Globals Definition*/

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  max-height: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #0a3051;
  font-size: 1rem;
  font-weight: 400;
  overflow-x: hidden;

  /*Prevent the User to Zoom The Page on Iphone*/
  touch-action: pan-x pan-y;
}

body > .noscroll {
  overflow: hidden;
}

/*Text Config*/

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 100%;
  color: #5db8ff;
  margin-top: 0px;
}

h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 2rem;
  line-height: auto;
  color: #ffffff;
  margin-top: 0px;
  text-align: left;
}

.p-roboto-bold-16 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 19px;
  margin-top: 0px;
  width: 99%;
  text-align: left;
}

.lightBlue {
  color: #5db8ff;
}

.darkBlue {
  color: #0a3051;
}

.white {
  color: #ffffff;
}

.red {
  color: #ef1b4e;
}

.p-roboto-black-16 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1rem;
  margin-top: 0px;
  text-transform: uppercase;
}

.p-roboto-regular-12-red {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #ef1b4e;
  margin-top: 0px;
}

.p-roboto-regular-16 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0px;
}

.p-roboto-regular-13 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin: 1px 0px;
}

.p-roboto-bold-10 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
}

.alert {
  position: relative;
  width: 100%;
  text-align: center;
  left: 50%;
  top: 2%;
  transform: translateX(-50%);
}

/*Link Config*/

a,
a:visited,
a:hover {
  text-decoration: none;
}

/*Flexbox*/

.flex {
  display: flex;
}
.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-c {
  justify-content: center;
}

.flex-ai-c {
  align-items: center;
}

/* margins */
.mt-15 {
  margin-top: 15px;
}

/* Errors */
.error {
  width: 312px;
  text-align: center;
  margin-top: 106px;
}

/*Buttons and Options*/

.btn-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 311px;
  height: 68px;

  background: none;
  border: 1px solid #f8b32d;
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #f8b32d;

  transition: all 300ms ease-in-out;
}
.btn-primary:active,
.btn-primary:hover {
  background: #f8b32d;
  color: #aa6c00;
}

.btn-secondary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 311px;
  height: 68px;

  background: #5db8ff;
  border: 1px solid #5db8ff;
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #0a3051;

  transition: all 300ms ease-in-out;
}

.btn-tertiary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 311px;
  height: 68px;

  background: none;
  border: 1px solid #ffffff;
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #ffffff;

  transition: all 300ms ease-in-out;
}

.btn-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 311px;
  height: 68px;

  background: none;
  border: 1px solid #ef1b4e;
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #ef1b4e;

  transition: all 300ms ease-in-out;
}

.btn-option-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 146px;
  height: 65px;

  background: none;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 28px;
  color: #ffffff;

  transition: all 300ms ease-in-out;
}

.btn-option-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 146px;
  height: 65px;

  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 28px;
  color: #0a3051;

  transition: all 300ms ease-in-out;
}

.btn-edit-red {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;

  position: relative;
  width: 145px;
  height: 68px;

  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 28px;
  color: #0a3051;

  transition: all 300ms ease-in-out;
}

/*Server Message Success/Error*/

.server-message-error {
  position: fixed;
  z-index: 1;
  left: 50%;
  margin-left: -175px;
  top: 5%;
  width: 350px;
  display: none;
  padding: 5px 12px;
  border-radius: 10px;
  color: white;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-color: #ef1b4e;
}

.server-message-success {
  position: fixed;
  z-index: 1;
  left: 50%;
  margin-left: -175px;
  top: 5%;
  width: 350px;
  display: none;
  padding: 5px 12px;
  border-radius: 10px;
  color: white;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-color: #1ec264;
}

/* Divider */

hr {
  width: 100%;
  border: 1px solid white;
}
