.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;
  height: 100%;
}
.h1 {
  width: 110%;
}

.p {
  width: 40%;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;

  letter-spacing: 0.03em;
}
