.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 32px 0 32px;
  width: 375px;
  height: 667px;
  h1 {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    color: #5db8ff;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ef1b4e;
    padding-bottom: 103px;
  }
}

.confirmButton {
  width: 100%;
}
