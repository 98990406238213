.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 32px 0 32px;
  margin: 0 auto;
  width: 365px;
  height: 100%;
  h1 {
    width: 100%;
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    color: #5db8ff;
  }
}

.backButton {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  margin-top: 32px;
}

.resume {
  width: 100%;
  height: 100%;
  padding-bottom: 36px;
}

.resume h5 {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}

.released {
  width: 100%;
  font-family: "Roboto";
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}

.morningPeriod {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 32px 2px;
  // margin: 32px;
  width: 365px;
  height: 100%;
  background-color: #5db8ff;
}

.period,
.morningButton {
  padding-top: 32px;
  margin-left: 28px;
}

.morningPeriod h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #0a3051;
  padding-bottom: 8px;
}

.morningPeriod p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto";
}

.morningDoing h5 {
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  padding: 32px 0 0 32px;
  margin: 0;
}

.morningDoing p {
  color: #ffffff;
  font-size: 13px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  padding-left: 32px;
  padding-top: 10px;
  margin: 0;
}

.photoLinks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: 1px underline white;
  margin: 15px auto;
}

.photoLinks span {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: 1px underline white;
}

.morningDoing span {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 32px;

  margin: 0;
}

.morningDoing hr {
  border: none;
  padding-left: 32px;
  height: 2px;
  background: #5db8ff;
  width: 145px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.separation {
  display: flex;
  align-items: flex-start;
}

.afternoonPeriod {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin: 32px;
  width: 365px;
  height: 123px;
  background-color: #5db8ff;
}

.signatureBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 667px;
  box-sizing: border-box;
}

.signatureTitleBox {
  flex: 0 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  // width: fit-content;
  // transform: rotate(-90deg);
  min-width: 0;
  height: 100%;
  // margin: 0;
  // padding: 0;
  p {
    color: #5db8ff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    transform: rotate(-90deg);
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-bottom: 50px;
    width: 667px;
    // line-height: 28px;
    // text-align: center;
  }
}

.closeIconButton {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 200px;
}

.signatureTitle {
  padding-bottom: 300px;
}

.signature {
  width: 228px;
  height: 667px;
}

.sendButton {
  width: 100%;
  padding-top: 40px;
}
