.photo-box {
  cursor: pointer;
  width: 100%;
  height: 163px;
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ffff;
  border-radius: 3px;
}

.input-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding-left: 30px;

  font-feature-settings: "liga" off;

  color: #ffffff;
}

.h1 {
  width: 110%;
}
