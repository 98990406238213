.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;
}

.plate-h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #ffff;
  margin: 0;
}
