body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  //   right: 0;
  //   bottom: 0;
  background: rgba(49, 49, 49, 0.8);
  z-index: 3;
}

.content {
  width: 90%;
  height: fit-content;
  img {
    height: 100%;
  }
}

.close-button-container {
  position: absolute;
  top: 5px;
  right: 5px;
}
