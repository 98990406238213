.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 32px 50px 32px;
  margin: 0 auto;
  width: 365px;
  height: 100%;
  h1 {
    width: 100%;
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    color: #5db8ff;
  }
}
