.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 32px;
  padding: 0 32px 0 32px;
  width: 375px;
  height: 100%;
  h1 {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    color: #5db8ff;
  }
}

.backButton {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.streetButton {
  width: 100%;
  padding-bottom: 64px;
}

.afternoonShift {
  width: 100%;
  padding-bottom: 24px;
}
