.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;
  height: 100%;
}

.h1 {
  width: 100%;
}

.hr {
  width: 145px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0px;
  border: 1px solid #5db8ff;
}

.p {
  width: 40%;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;

  letter-spacing: 0.03em;
}
