.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  width: 110%;
  height: 100%;
}

.h1 {
  width: 80%;
}
